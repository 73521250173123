import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import useApiCall from 'hooks/use-api-call';
import { AdminApi } from 'api/apis';
import LoadingRing from 'ui/atoms/loading-ring';
import { AdminIssuerDetail } from 'api/models';
import BoxedContent from 'ui/molecules/boxed-content';
import Section from 'ui/atoms/section';
import IssuerData from './issuer-data-view';
import Spacer from 'ui/atoms/spacer';
import Header from 'ui/atoms/header';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Number from 'ui/atoms/number';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import CopyButton from 'ui/molecules/copy-button';
import IssuerDetailsProducts from 'apps/issuer/pages/issuer-details/issuer-products';
import { currentIssuerHasEditIssuerPermission } from 'apps/issuer/helpers/issuerPermissions';

interface IssuerIssuerDetailsPageProps {
  issuerId: string;
}

const IssuerIssuerDetailsPage: FunctionComponent<IssuerIssuerDetailsPageProps> = ({ issuerId }) => {
  const [issuer, setIssuer] = useState<AdminIssuerDetail>();

  const { withApi, makeAuthenticatedApi } = useApiCall();

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const isPhone = useIsMedia(DEVICES.phone);

  const fetchIssuerDetails = useCallback(() => {
    withApi(async () => {
      const issuerDetails = await adminApi.adminIssuersRetrieve({ id: issuerId });
      setIssuer(issuerDetails);
      // get products of an issuer
    });
  }, [issuerId]);

  useEffect(() => {
    fetchIssuerDetails();
  }, [withApi, adminApi, issuerId]);

  const canEdit = useMemo(() => currentIssuerHasEditIssuerPermission(issuer?.permissions || []), [issuer?.permissions]);

  return (
    <>
      <Section spacing="medium">
        <WideContent>
          <Header size="large">
            <Translate name="dashboardIssuerIssuerDetails.title" args={[issuer?.legalPerson?.companyName]} />
          </Header>
          <Spacer x={4} />

          {!issuer && <LoadingRing />}
          {issuer && (
            <>
              <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
                <InfoGrid columns={isPhone ? 1 : 2}>
                  <InfoField title={<Translate name="dashboardIssuerIssuerDetails.generalInformation" />}>
                    <span>
                      <Translate name="dashboardIssuerIssuerDetails.issuerId" />
                    </span>
                    <span>{issuerId}</span>
                    <Spacer x={1} />
                    <CopyButton valueToCopy={issuerId} />
                  </InfoField>
                  <InfoField title={<Translate name="dashboardIssuerIssuerDetails.productNumber" />}>
                    <PlaceholderFallback>
                      <Number>{issuer.numberOfProducts}</Number>
                    </PlaceholderFallback>
                  </InfoField>
                </InfoGrid>
              </BoxedContent>
              <IssuerData issuer={issuer} canEdit={canEdit} fetchIssuerDetails={fetchIssuerDetails} />
            </>
          )}
        </WideContent>
      </Section>
      <IssuerDetailsProducts issuerId={issuerId} />
    </>
  );
};

export default WithAuthenticatedPage(IssuerIssuerDetailsPage, AppType.ISSUER);
