import React, { FunctionComponent } from 'react';
import Header from 'ui/atoms/header';
import Translate from 'ui/atoms/translate';
import Segment from 'ui/atoms/segment';
import Currency from 'ui/atoms/currency';
import BoxedContent from 'ui/molecules/boxed-content';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import { camelCase } from 'change-case';
import { AdminProductDetail, AdminTokenOverview, Issuer } from 'api';
import Grid, { Col } from 'ui/atoms/grid';
import * as Styled from './styled';
import Icon from 'src/ui/atoms/icon';
import ProductAccordion from './product-accordion';
import Spacer from 'src/ui/atoms/spacer';

export interface ProductOverviewProps {
  data: AdminTokenOverview & AdminProductDetail;
  issuer: Issuer;
  refreshProductData: () => void;
  hasEditIssuanceDocumentsPermission: boolean;
}

const ProductOverview: FunctionComponent<ProductOverviewProps> = (props) => {
  const { data, issuer, refreshProductData, hasEditIssuanceDocumentsPermission } = props;

  return (
    <>
      <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
        <InfoGrid columns={3}>
          <InfoField title={<Translate name="dashboardIssuerProductDetails.productType" />}>
            <Translate name={`productType.${camelCase(data.productType)}`} />
          </InfoField>
          <InfoField title={<Translate name="dashboardIssuerProductDetails.totalVolume" />}>
            <Currency>{data.totalVolume}</Currency>
          </InfoField>
          <InfoField title={<Translate name="dashboardIssuerProductDetails.issuer" />}>
            {data.companyName}
            <Spacer x={1} />
            <a href={`/dashboard/issuers/${issuer.id}`}>
              <Icon name="link" />
            </a>
          </InfoField>
          <InfoField title={<Translate name="dashboardIssuerProductDetails.network" />}>
            {data.isTokenized ? (
              <>
                <Styled.Network>{data.network}</Styled.Network>
                (<Translate name="dashboardIssuerProductDetails.tokenized" />)
              </>
            ) : (
              <Translate name="dashboardIssuerProductDetails.notTokenized" />
            )}
          </InfoField>
          <InfoField title={<Translate name="entryType.label" />}>
            <Translate name={`entryType.${data.entryType}`} />
          </InfoField>
        </InfoGrid>
        <ProductAccordion
          data={data}
          refreshProductData={refreshProductData}
          hasEditIssuanceDocumentsPermission={hasEditIssuanceDocumentsPermission}
        />
      </BoxedContent>
      <div>
        <Grid spacing="large">
          <Col width={4} tablet={12} phone={12} spacing="medium">
            <Segment raised={true}>
              <Header size="tiny" color="grayDark" spacing="medium" truncate={true} uppercase={true}>
                <Translate name="dashboardIssuerProductDetails.investments" />
              </Header>
              <Styled.HeaderRow>{data?.numberActiveInvestments}</Styled.HeaderRow>
            </Segment>
          </Col>
          <Col width={4} tablet={12} phone={12} spacing="medium">
            <Segment raised={true}>
              <Header size="tiny" color="grayDark" spacing="medium" truncate={true} uppercase={true}>
                <Translate name="dashboardIssuerProductDetails.totalAmount" />
              </Header>
              <Styled.HeaderRow>
                <Currency>{data?.sumActiveInvestments}</Currency>
              </Styled.HeaderRow>
            </Segment>
          </Col>
          <Col width={4} tablet={12} phone={12} spacing="medium">
            <Segment raised={true}>
              <Header size="tiny" color="grayDark" spacing="medium" truncate={true} uppercase={true}>
                <Translate name="dashboardIssuerProductDetails.sumPaymentsReceived" />
              </Header>
              <Styled.HeaderRow>
                <Currency>{data?.sumPaymentsReceived}</Currency>
              </Styled.HeaderRow>
            </Segment>
          </Col>
        </Grid>
      </div>
    </>
  );
};

export default ProductOverview;
